import { IntlShape } from 'react-intl';
import { ReactComponent as IconPower } from '@whitelabel/media/icons/small/power.svg';
import { ReactComponent as IconArrowLeft } from '@whitelabel/media/icons/small/arrow-left.svg';
import messages from './messages/commonMsg';
import { isRCAffiliateLink } from './site';

export interface INavParent {
  key: string;
  name: string;
  children: INavParent[] | INavLink[];
}

export interface INavLink {
  key: string;
  name: string;
  href: string;
}

export const isNavParent = (nav: INavParent | INavLink): nav is INavParent =>
  (nav as INavParent).children !== undefined;

export const isNavLink = (nav: INavParent | INavLink): nav is INavLink => (nav as INavLink).href !== undefined;

const helpArticleDropdownLinks = (intl: IntlShape): INavParent => ({
  key: 'helparticle',
  name: intl.formatMessage(messages.helpArticleLinks),
  children: [
    {
      key: 'excesscover',
      href: `/excess-cover`,
      name: intl.formatMessage(messages.explainSubmenu),
    },
    {
      key: 'howitworks',
      href: `/how-it-works`,
      name: intl.formatMessage(messages.howItWorksSubmenu),
    },
    {
      key: 'whyuserentalcover',
      href: `/site/contact/97`,
      name: intl.formatMessage(messages.whySubmenu),
    },
  ],
});

const rentalGuideDropdownLinks = (intl: IntlShape): INavParent => ({
  key: 'rentalguide',
  name: intl.formatMessage(messages.rentalGuideLinks),
  children: [
    {
      key: 'rentalguide-us',
      href: `/car-rental-insurance-united-states`,
      name: 'United States',
    },
    {
      key: 'rentalguide-uk',
      href: `/car-rental-insurance-united-kingdom`,
      name: 'United Kingdom',
    },
    {
      key: 'rentalguide-au',
      href: `/car-rental-insurance-australia`,
      name: 'Australia',
    },
    {
      key: 'rentalguide-ca',
      href: `/car-rental-insurance-canada`,
      name: 'Canada',
    },
    {
      key: 'rentalguide-cn',
      href: `/car-rental-insurance-china`,
      name: 'China',
    },
    {
      key: 'rentalguide-fr',
      href: `/car-rental-insurance-france`,
      name: 'France',
    },
    {
      key: 'rentalguide-it',
      href: `/car-rental-insurance-italy`,
      name: 'Italy',
    },
    {
      key: 'rentalguide-ja',
      href: `/car-rental-insurance-japan`,
      name: 'Japan',
    },
    {
      key: 'rentalguide-mx',
      href: `/car-rental-insurance-mexico`,
      name: 'Mexico',
    },
    {
      key: 'rentalguide-row',
      href: `/car-rental-insurance`,
      name: 'Worldwide',
    },
  ],
});

export const howItWorksDropdownLinks = (intl: IntlShape): INavParent => ({
  key: 'howitworks',
  name: intl.formatMessage(messages.howItWorksDropdownLinks),
  children: [helpArticleDropdownLinks(intl), rentalGuideDropdownLinks(intl)],
});

export const protectionLink = (intl: IntlShape) => ({
  key: 'protection',
  messageKey: 'yourProtection',
  path: isRCAffiliateLink() ? '/protection' : '/account',
  name: intl.formatMessage(messages.yourProtectionLinkText),
});

export const profileLink = (intl: IntlShape) => ({
  key: 'profile',
  messageKey: 'yourProfile',
  path: '/account/profile',
  name: intl.formatMessage(messages.yourProfileLinkText),
});

export const claimsLink = (intl: IntlShape) => ({
  key: 'claims',
  messageKey: 'yourClaims',
  path: '/claims',
  name: intl.formatMessage(messages.yourClaimsLinkText),
});

export const claimDetailsLink = (intl: IntlShape, claimReference: string) => ({
  key: 'claimDetails',
  messageKey: 'yourClaims',
  path: `/claims/${claimReference}/details`,
  name: intl.formatMessage(messages.yourClaimsLinkText),
  icon: IconArrowLeft,
});

export const logoutLink = (intl: IntlShape) => ({
  key: 'logout',
  messageKey: 'logout',
  path: '/logout-customer',
  dataAnalytics: 'page.navbar.btn.logout',
  icon: IconPower,
  name: intl.formatMessage(messages.logoutLinkText),
});
