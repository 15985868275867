import { camelCase, mapKeys, snakeCase } from 'lodash/fp';
import { captureExceptionWithFullStory } from '@whitelabel/helpers/utils';
import { INavItem } from '@whitelabel/helpers/types';
import { IPage, IXCMSContentBlock } from '@whitelabel/helpers/types/xcmsTypes';
import { IClaimBooking } from './types';
import { CP_ROOT } from './constants';

//  Convert a string to snake case with few custom convertion logic
const customSnakeKeys = (key: any) => {
  const matches = {
    birthDate: 'date_of_birth',
    postcode: 'postal_code',
    postalCode: 'postal_code',
    address1: 'address1',
    address2: 'address2',
    region: 'state_province',
    state: 'state_province',
  };
  return matches[key as keyof typeof matches] || snakeCase(key);
};

const customCamelKeys = (key: any) => {
  const matches = {
    date_of_birth: 'birthDate',
    postal_code: 'postcode',
    state_province: 'region',
  };
  return matches[key as keyof typeof matches] || camelCase(key);
};

/**
 * Returns an object with field name keys similar to response. Eg: first name transform to first_name (not firstName)
 */
export function updateClaimantKeys<T>(claimant: any, convertToCamelCase = false) {
  return mapKeys(convertToCamelCase ? customCamelKeys : customSnakeKeys)(claimant) as unknown as T;
}

export function processHelpArticleLink(link?: string, fieldName?: string) {
  if (!link) return {};
  // verify if the help article link is from xcover
  // use the current domain as the origin if helpArticleLink is configured with relative path
  const helpArticleURL = new URL(link, window.location.origin);
  const isLinkCorrect = helpArticleURL.host.includes(CP_ROOT);

  // get the help article slug from the link
  const helpArticleSlug = isLinkCorrect ? helpArticleURL.pathname.split('/').filter(Boolean).pop() : undefined;
  const locale = window.location.pathname.split('/').filter(Boolean).shift();

  let helpArticleLink: string | undefined = `${helpArticleURL.origin}/${locale}/help/${helpArticleSlug}`;

  if (!isLinkCorrect) {
    captureExceptionWithFullStory(
      new Error(`The help article link is incorrect: ${helpArticleLink} for: ${fieldName}`),
    );
    // if the link is incorrect, remove the link
    helpArticleLink = undefined;
  }

  if (isLinkCorrect && !helpArticleSlug) {
    captureExceptionWithFullStory(
      new Error(`The help article slug is not found from the link: ${helpArticleLink} for: ${fieldName}`),
    );
  }
  return { helpArticleLink, helpArticleSlug };
}

export function getPolicyTypeGroupName(claimBooking?: IClaimBooking, quoteID?: string) {
  const { quotes } = claimBooking || {};
  const { policy } = quotes?.find(({ id }) => id === quoteID) || {};
  return policy?.policyTypeGroupName;
}

/**
 * Gets the appropriate next or vite public asset path for the build mode (local, commit, staging, production). based on the root.
 *
 * @param root - The root of the application, either 'xcover' or 'rentalcover'.
 */
export const getAssetPath = (root: typeof CP_ROOT) => {
  if (root === 'xcover') {
    return process.env.NEXT_PUBLIC_ASSET_PREFIX;
  }

  // If VITE_PUBLIC_PATH is truthy (i.e. it's not undefined or an empty string) return it with a prepending `/` to make it an absolute path.
  if (process.env.VITE_PUBLIC_PATH) {
    return `/${process.env.VITE_PUBLIC_PATH}`;
  }

  return process.env.VITE_PUBLIC_PATH;
};

export const updateNavPath = (navItem: INavItem[], targetKeyName: string, targetKeyValue: string) =>
  navItem.map((navObject) => {
    if (navObject.key === targetKeyName) {
      navObject.path = targetKeyValue;
      return navObject;
    }
    return navObject;
  });

/**
 * Replace template variables {{varName}} with their replacement values in rich text content (Including embedded links etc)
 * @param pageBody Array of CMS content blocks
 * @param replacements An object with keys as template variables and values as replacements
 * @returns Processed page body with template variables replaced
 * @example
 * const processedPageBody = processContentReplacers(page.body, {
 *   INS: claimBooking.id,
 *   POLICY_TYPE_GROUP_NAME: getPolicyTypeGroupName(claimBooking),
 * });
 *
 * 'pds/{{INS}}' -> 'pds/123'
 * '{{POLICY_TYPE_GROUP_NAME}}' -> 'Life'
 */
export const processContentReplacers = (
  pageBody: IPage['body'] | undefined,
  replacements: Record<string, string | undefined>,
): IPage['body'] | undefined => {
  if (!replacements) {
    return pageBody;
  }

  if (!pageBody) return undefined;

  // Create a deep copy to avoid mutating the original
  const processedBody = JSON.parse(JSON.stringify(pageBody)) as IPage['body'];

  // Process each block
  const processBlock = (block: IXCMSContentBlock) => {
    // Process rich text blocks
    if (block.type === 'rich_text' && typeof block.value === 'string') {
      let processedValue = block.value;
      Object.entries(replacements).forEach(([placeholder, value]) => {
        if (value) {
          processedValue = processedValue.replace(new RegExp(`{{\\s*${placeholder}\\s*}}`, 'g'), value);
        }
      });
      block.value = processedValue;
    }

    // Process section blocks
    if (block.type === 'section' && Array.isArray(block.value)) {
      block.value.forEach(processBlock);
    }
  };

  processedBody.forEach(processBlock);

  return processedBody;
};
