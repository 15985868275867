import styled, { css } from 'styled-components';
import { fontStyle, fontWeight, lineHeight, resetLinkStyle } from '@whitelabel/helpers/style/type';
import { themeColor } from '@whitelabel/helpers/style/colors';

export const StyledHeading = styled.div`
  line-height: ${lineHeight('headings')};
`;

export const StyledLocaleListItem = styled.li`
  ${fontStyle('contentMenu')}
  margin-bottom: 1rem;
  padding: 0;
  list-style-type: none;
`;

export const StyledLocaleLink = styled.a<{ $active: boolean }>`
  ${resetLinkStyle}
  font-weight: ${fontWeight('normal')};
  text-transform: capitalize;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  ${({ $active }) =>
    $active &&
    css`
      :not([href]):not([tabindex]) {
        color: ${themeColor('success')};
        font-weight: ${fontWeight('bold')};
      }
    `}
  :not([href]):not([tabindex]):hover {
    color: ${themeColor('success')};
    background: transparent;
    font-weight: ${fontWeight('bold')};
  }
`;
