import React, { ReactElement, useState } from 'react';

import ToggleButton from '../../ToggleButton';
import { StyledCollapse, StyledCollapseButton } from './styledCollapsibleText';

interface ICollapsibleText {
  firstElement: ReactElement;
  restElement: ReactElement;
}

const CollapsibleText = ({ firstElement: shownElement, restElement: hiddenElement }: ICollapsibleText) => {
  const [isCollapsed, setCollapse] = useState(true);

  const toggleCollapse = () => {
    setCollapse((collapse) => !collapse);
  };

  return (
    <>
      {shownElement}
      <StyledCollapse isOpen={!isCollapsed}>{hiddenElement}</StyledCollapse>
      <StyledCollapseButton>
        <ToggleButton isCollapsed={isCollapsed} toggleCollapse={toggleCollapse} />
      </StyledCollapseButton>
    </>
  );
};

export default CollapsibleText;
