import styled from 'styled-components';
import Button from '@whitelabel/component-library/src/components/Button';

export const StyledButton = styled(Button)`
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 0.25rem;

  svg {
    margin-inline-end: 0.5rem;
  }
`;

export const StyledCollapseText = styled.span`
  padding: 0;
`;
