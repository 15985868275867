import React from 'react';
import { AlertProps as ReactstrapAlertProps } from 'reactstrap';
import { ReactComponent as IconSuccess } from '@whitelabel/media/icons/success-currentcolor.svg';
import { ReactComponent as IconDanger } from '@whitelabel/media/icons/danger-currentcolor.svg';
import { ReactComponent as IconWarning } from '@whitelabel/media/icons/warning-currentcolor.svg';
import { ReactComponent as IconInfo } from '@whitelabel/media/icons/info-currentcolor.svg';
import { ReactComponent as Cross } from '@whitelabel/media/icons/small/cross.svg';
import { AlertColor } from '@whitelabel/helpers/types';
import { StyledAlert, StyledIcon, StyledButton, StyledAlertContent } from './styledAlert';

const icons = {
  success: IconSuccess,
  danger: IconDanger,
  warning: IconWarning,
  info: IconInfo,
};

export interface IAlertProps extends ReactstrapAlertProps {
  color: AlertColor;
  children: React.ReactNode;
  isOpen?: boolean;
  toggle?: () => void;
  className?: string;
  customIcon?: React.FunctionComponent<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>>;
}

const Alert = ({
  color,
  children,
  isOpen = true,
  toggle,
  className,
  customIcon,
  ...props
}: IAlertProps): JSX.Element => {
  const Icon = customIcon ?? icons[color];

  return (
    <StyledAlert {...props} toggle={toggle} color={color} isOpen={isOpen} className={className} data-test-id="Alert">
      {toggle && <StyledButton buttonColor={color} icon={Cross} aria-label="Close" onClick={toggle} />}
      <StyledIcon as={Icon} data-test-id="Alert-icon" />
      <StyledAlertContent data-test-id="Alert-content">{children}</StyledAlertContent>
    </StyledAlert>
  );
};

export default Alert;
