export * from './partner_id';
export * from './experiment';

export const KW_LOCAL_STORAGE_QUESTIONNAIRE_KEY = 'xcover-kw-questionnaire';
export const KW_LOCAL_STORAGE_POLICY_KEY = 'xcover-kw-policy';
export const KW_AGENT_INFO = 'kwAgentInfo';
export const KW_ROLE_AGENT = 'agent';

export const GENERALI_UNDERWRITER_NAME = 'Generali US';

export const CLAIM_JOURNEY_ID = 'claimJourneyID';
export const CLAIM_JOURNEY_DATA = 'claimJourneyData';
export const SAVED_CLAIMS = 'savedClaims';
export const LAST_CLAIM_TYPE_ID = 'lastClaimTypeID';
export const EBAY_POLICY_RENEWED = 'ebayPolicyRenewed';
export const PAYMENT_SECTION_ID = 'payment-details';

export const MEDIA_TYPE = {
  EMAIL: 'email',
  PHONE: 'phone',
};

export const SEARCH_TYPE = {
  REF_NUMBER: 'refNumber',
  TRACKING_NUMBER: 'trackingNumber',
};

export const SECURITY_TOKEN = 'securityToken';
export const XCOVER_TOKEN = 'xcoverToken';
export const XPAY_TOKEN = 'xpayToken';
export const CONNECTED_TO_PAYPAL = 'connectedToPaypal';
export const CLOSE_PAYPAL_MODAL = 'closePaypalModal';
export const PAYOUT_BOOKINGID = 'payoutBookingID';
export const SKIP_SUMMARY_PAGE = 'skipSummaryPage';
export const ZENDESK_CHAT_PARTNER = 'zendeskChatPartner';

export const SOURCE = 'source';
export const SOURCE_SMS = 'sms';
export const SOURCE_EMAIL = 'email';

export const SIGNUP = 'signup';
export const SIGNUP_PHONE = 'phone';
export const EMAIL_PARAM = 'email';
export const BOOKING_ID = 'bookingID';
export const COOKIE_YES_MODAL_ACTIONED = 'cookieYesModalActioned';
export const hiddenLocaleCodes = ['en-us'];
export const PAYMENT_METHODS_MANAGEMENT = 'paymentMethodsManagement';

// Avoid to use this constant if possible,
// Use partner id constant instead for accuracy.
// eg: EBAY_PARTNER_ID
export const PARTNER_SLUG = {
  skyscanner: 'skyscanner',
};

export const CHARGE_TYPES = {
  AUTHORISATION: 'AUTHORISATION',
  ONE_OFF: 'ONE_OFF',
} as const;

// Regex
export const REGEX_INS_ALL_CASES = /([A-Z0-9]-)?([A-Z0-9]{5}-){2}INS/i;
export const REGEX_INS = /^([A-Z0-9]-)?([A-Z0-9]{5}-){2}INS$/;
export const REGEX_RC_INS = /^([A-Z0-9]-)?([A-Z0-9]{4}-){2}INS$/;
export const REGEX_UUID = /[a-z0-9]{8}-([a-z0-9]{4}-){3}[a-z0-9]{12}/;

export const PUBLIC_NAV_ITEMS = [
  {
    key: 'about',
    messageKey: 'aboutLink',
    name: 'About',
    path: '/about',
  },
];

export const YOUR_PROTECTION_LINK = {
  key: 'account',
  messageKey: 'accountLink',
  name: 'Your Policies',
  path: '/account',
  activePaths: ['/coi/', '/modify/', '/pds/', '/notify/'],
};

export const YOUR_OFFERS_LINK = {
  key: 'yourOffers',
  messageKey: 'yourOffers',
  name: 'Offers',
  path: '/offers',
};

export const MAKE_A_CLAIM_LINK = {
  key: 'makeClaim',
  messageKey: 'makeClaimLink',
  name: 'Your Claims',
  path: '/claims/',
  tag: 'a' as React.ElementType,
};

export const ACCOUNT_NAV_ITEMS = [YOUR_PROTECTION_LINK, MAKE_A_CLAIM_LINK];

export const MIME_TYPES = {
  jpeg: ['image/jpeg', 'image/pjpeg'],
  jpg: ['image/jpeg', 'image/pjpeg'],
  tiff: ['image/tiff'],
  gif: ['image/gif'],
  bmp: ['image/bmp', 'image/x-bmp', 'image/x-ms-bmp'],
  png: ['image/png'],
  svg: ['image/svg+xml'],
  pdf: ['application/pdf', 'application/acrobat', 'application/nappdf', 'application/x-pdf', 'image/pdf'],
  mp4: ['video/mp4', 'video/mp4v-es', 'video/x-m4v'],
  avi: ['video/avi', 'video/divx', 'video/msvideo', 'video/vnd.divx', 'video/x-avi', 'video/x-msvideo'],
  mov: ['video/quicktime'],
  wmv: ['audio/x-ms-wmv', 'video/x-ms-wmv'],
  mpg: ['video/mpeg', 'video/mpeg-system', 'video/x-mpeg', 'video/x-mpeg-system', 'video/x-mpeg2'],
  mpeg: ['video/mpeg', 'video/mpeg-system', 'video/x-mpeg', 'video/x-mpeg-system', 'video/x-mpeg2'],
  webm: ['video/webm'],
  flv: ['video/x-flv', 'application/x-flash-video', 'flv-application/octet-stream', 'video/flv'],
  csv: ['text/csv'],
  txt: ['text/plain'],
};

export const SITE_NAME = 'XCover.com';
export const RENTALCOVER_SITE_NAME = 'Rentalcover.com';

export const MAP_ERROR_CODE_TO_STATIC_PAGE_SLUG: Partial<Record<string, string>> = {
  'XCLAIM.FNOL_ACCESS.CLAIM_LIMIT_REACHED': 'claim_already_submitted_fnol_error',
};

export const POSTCODE_PARAM = 'param';

// zendesk custom field ids
export const LANGUAGE_FILED_ID = 360000222295;
export const CUSTOMER_RATING_FIELD_ID = 360013227335;

export const TRUSTPILOT_REVIEW_URL = 'https://www.trustpilot.com/evaluate/xcover.com'; // updated based on business request
export const RENTALCOVER_URL = 'https://www.rentalcover.com/';
export const EDGE_CG_SERVICES_URL = 'https://edge.covergenius.biz/api/v1';

export enum IMPERSONATE {
  CUSTOMER_ID = 'customerId',
  ADMIN_USERNAME = 'adminUsername',
  CUSTOMER_EMAIL = 'customerEmail',
}
export const WRONG_USERNAME_OR_PASSWORD = 'Incorrect username or password.';
export const MAX_BOOKINGS_PER_REVIEW = 20;
export const ENABLE_QUOTE_AND_BOOKING = false;
export const HELP_CENTER_HOMEPAGE_SLUG = 'help-center';
export const LOGIN_MODAL_HEADER_SLUG = 'login-modal-header';
export const HELP_LOGIN_MODAL_DISPLAYED = 'helpLoginModalDisplayed';
export const PDS_LOGIN_MODAL_DISPLAYED = 'pdsLoginModalDisplayed';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ERROR_CODE {
  BOOKING_GDPR_CUSTOMER_NOT_EXISTED = '20160',
  ALREADY_REGISTERED = '20110',
  EXTERNAL_SERVICE_ERROR = '10000',
  XCORE_GENERIC_ERROR = '10100',
  XCMS_GENERIC_ERROR = '10200',
  XCLAIM_GENERIC_ERROR = '10300',
  XPAY_GENERIC_ERROR = '10400',
  GENERIC_SIGNUP_ERROR = '20100',
  TOKEN_MISMATCH = '20120',
  EMAIL_NOT_FOUND = '20140',
  PHONE_NOT_FOUND = '20150',
  CANNOT_CANCEL_INDIVIDUALLY = '20180',
  PAYPAL_ALREADY_CONNECTED = '20190',
}
export const stateMapUSCA = {
  alabama: 'AL',
  alaska: 'AK',
  arizona: 'AZ',
  arkansas: 'AR',
  california: 'CA',
  colorado: 'CO',
  connecticut: 'CT',
  delaware: 'DE',
  florida: 'FL',
  georgia: 'GA',
  hawaii: 'HI',
  idaho: 'ID',
  illinois: 'IL',
  indiana: 'IN',
  iowa: 'IA',
  kansas: 'KS',
  kentucky: 'KY',
  louisiana: 'LA',
  maine: 'ME',
  maryland: 'MD',
  massachusetts: 'MA',
  michigan: 'MI',
  minnesota: 'MN',
  mississippi: 'MS',
  missouri: 'MO',
  montana: 'MT',
  nebraska: 'NE',
  nevada: 'NV',
  'new hampshire': 'NH',
  'new jersey': 'NJ',
  'new mexico': 'NM',
  'new york': 'NY',
  'north carolina': 'NC',
  'north dakota': 'ND',
  ohio: 'OH',
  oklahoma: 'OK',
  oregon: 'OR',
  pennsylvania: 'PA',
  'rhode island': 'RI',
  'south carolina': 'SC',
  'south dakota': 'SD',
  tennessee: 'TN',
  texas: 'TX',
  utah: 'UT',
  vermont: 'VT',
  virginia: 'VA',
  washington: 'WA',
  'west virginia': 'WV',
  wisconsin: 'WI',
  wyoming: 'WY',
  alberta: 'AB',
  'british columbia': 'BC',
  manitoba: 'MB',
  'new brunswick': 'NB',
  'newfoundland and labrador': 'NL',
  'nova scotia': 'NS',
  nunavut: 'NU',
  'northwest territories': 'NT',
  ontario: 'ON',
  'prince edward island': 'PE',
  quebec: 'QC',
  saskatchewan: 'SK',
  yukon: 'YT',
};

export const BOOKINGS_PER_PAGE = 50;
export const SHOW_MODIFY_SUCCESS = 'showModifySuccess';
export const CES_SELECTED_LIST = 'cesSelectedList';
export const NPS_ACCOUNT_SELECTED = 'npsAccountSelected';

export const NPS_EMAIL_MODAL_PARAMS = {
  BOOKING_ID: 'bookingID',
  SCORE: 'score',
  SOURCE: 'source',
  NPS_SOURCE: 'nps_source',
};

export const NPS_SMS_MODAL_PARAMS = {
  BOOKING_ID: 'bookingID',
  SOURCE: 'source',
  NPS_SOURCE: 'nps_source',
};

export const US_SPECIAL_STATES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'DE',
  'DC',
  'FL',
  'HI',
  'ID',
  'IN',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MN',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'TN',
  'TX',
  'VT',
  'VA',
  'WA',
  'WV',
];

export const RC_US_SPECIAL_STATES = {
  US_FIRE: [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'DE',
    'DC',
    'FL',
    'HI',
    'ID',
    'IN',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MN',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NY',
    'OH',
    'OK',
    'OR',
    'PA',
    'TN',
    'TX',
    'VT',
    'VA',
    'WA',
    'WV',
  ],
  NATION_WIDE: [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'DE',
    'DC',
    'FL',
    'ID',
    'IN',
    'KY',
    'LA',
    'ME',
    'MD',
    'MN',
    'NH',
    'NJ',
    'NM',
    'NY',
    'OH',
    'OK',
    'PA',
    'RI',
    'TN',
    'TX',
    'VA',
    'WA',
    'WV',
  ],
};

export const FE_MESSAGES_SLUG = '64b636e3-5744-48bb-aaa8-5b655fa12c56';
export const KW_FE_MESSAGES_SLUG = 'xcovercom-kw-fe-messages';

export const KW_GOLD = `gold`;
export const KW_PLATINUM = `platinum`;
export const KW_SILVER = `silver`;

export const CUSTOMER_REGION = 'customerRegion';
export const IS_EXISTING_LWA_USER = 'isExistingLWAUser';
export const EU_CENTRAL_1 = 'eu-central-1';
export const AP_SOUTH_1 = 'ap-south-1';
export const US_EAST_1 = 'us-east-1';

export const XPAY_PAYPAL_MESSAGE_SUCCESS = 'success';
export const XPAY_PAYPAL_MESSAGE_FAILURE = 'failure';

export const OBJECT_STRING = '[object Object]';
export const RTK_CACHE_LIFE = 900;

export const COOKIE_YES_COOKIE_NAME = 'cookieyes-consent';
export const COOKIE_MARKETING = 'advertisement';

export const RC_BANNER_FEATURE_FLAG_KEY = 'rcBannerPartnerBlackList';

export const PROTECTION_WALLET = 'protection-wallet';
export const PROTECTION_WALLET_FEATURE_FLAG_KEY = 'protectionWalletPartnerWhiteList';

export const CUSTOMER_CURRENCY = 'customer_currency';

export const CONTACT_JOURNEY_ID = 'contactJourneyID';

export const LWA_LOCALE = 'LWALocale';

export const LWA_SIGNATURE = 'signature';

export const ERROR_CANNOT_LOG_IN = 'cannot_log_in_error';

export const LWA_ERROR_TYPE = 'lwa_error_type';

export const SIGNOUT_REDIRECT_TO_URL = 'signoutRedirectToUrl';

export const SIGNIN_CALLBACK_PATH = '/login-with-amazon-callback';

export const SIGNOUT_CALLBACK_PATH = '/signout-callback';

export const LWA_POST_LOGIN_REDIRECT = 'lwa_post_login_redirect';

export const TICKETING_PARTNERS = [
  'gametime-united-inc',
  'ticket_swap',
  'futureticketing',
  'humanitix',
  'easol',
  'megatix',
  'city-winery',
  'q-tickets',
  'eventix',
  'audienceview',
  'epic_events',
  'silverstone',
  'lovetovisitcom',
  'findarace',
  'si-entries',
  'patron_base_nz',
  'ticketor',
  'fr_systems',
  'ourea-events-limited',
  'ticketebo_uk',
  'kelsey-media',
  '1boxofficecom',
  'lionhearts-events-ltd-tas-wolfrun',
  'quicket',
  'thursford_collection',
  'zaiko',
  'skyline-races',
  'ticketebo_au',
  'emedia_campaigns',
  'race-space-limited',
  'oz_tix',
  'red',
  'digital-sports-group-limited',
  'netpositive',
  'eventplus',
  'entrycentral',
  'securemedia',
  'churnet-valley-railway',
  'ticket-master-australia',
  'outbox',
  'idt',
  'taquilla',
  'billetto_uk',
  'bileternet',
  'ticketsource',
  'tribourne-multisport-events',
  'entrywide',
  'we-scan-tickets',
  'mack_events',
  'music-opera',
  'freshtix',
  'bilety',
  'vivaticket',
  'patron_base',
  'ticombo',
  'motorsport_tickets',
  'bita',
  'ticketsellers',
  'axs-us',
  'tickpick',
  'funraisin',
  'axs',
  'outbox-singlepay',
];

export const AMAZON_COUNTRY_CODES = ['GB', 'FR', 'ES', 'DE', 'IT'];

export const ANNUAL_POLICY_TYPE_SLUG = 'comprehensive_travel_insurance_v7';
