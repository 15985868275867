import styled from 'styled-components';
import { Collapse } from 'reactstrap';
import { gray } from '@whitelabel/helpers/style/colors';
import { fontWeight } from '@whitelabel/helpers/style/type';

export const StyledCollapseButton = styled.div`
  padding: 0;

  button {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;

    svg {
      color: ${gray(600)};
    }
  }

  span {
    font-weight: ${fontWeight('black')};
    font-size: 0.625rem;
    line-height: 1rem;
    text-transform: uppercase;

    color: ${gray(700)};
  }
`;

export const StyledCollapse = styled(Collapse)`
  padding: 0;
`;
