import styled, { css } from 'styled-components';
import { mediaBreakpointUp, marginBottom } from '@whitelabel/helpers/style/grid';

interface IStyledManualAddressInput {
  $hideAddressLine1: boolean;
}

export const StyledManualAddressInput = styled.div<IStyledManualAddressInput>`
  .input-wrapper {
    display: block;
    width: 100%;
    &:not(:last-child) {
      ${marginBottom}
    }

    ${mediaBreakpointUp('md')`
      max-width: 23rem;
    `}
  }

  ${({ $hideAddressLine1 }) =>
    $hideAddressLine1 &&
    css`
      > .input-wrapper:first-child {
        margin-top: 1rem;

        ${mediaBreakpointUp('md')`
          margin-top: 1.5rem;
        `}

        ${mediaBreakpointUp('lg')`
          margin-top: 2rem;
        `}
      }
    `}
`;
