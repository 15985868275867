import styled from 'styled-components';
import { NavbarBrand } from 'reactstrap';
import { hideVisually, rem } from 'polished';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import type { IBrandProps } from './Brand';

export const StyledBrand = styled(NavbarBrand)`
  padding: 0;
  border-bottom: 0;
  height: fit-content;
  display: block;

  &:hover {
    background-color: inherit;
    box-shadow: none;
  }
`;

export const StyledBrandLogo = styled.img<{ size: IBrandProps['size'] }>`
  display: block;
  width: ${rem(190)};
  ${mediaBreakpointUp('md')`
      width: ${rem(227)};
  `};
`;

export const StyledBrandTitle = styled.span`
  ${hideVisually()}
`;
