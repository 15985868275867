import { lazy } from 'react';

const ClaimsLanding = lazy(() => import('../containers/pages/ClaimsLanding'));
const ClaimDetails = lazy(() => import('../containers/pages/ClaimDetails'));
const ClaimDetailsDocument = lazy(() => import('../containers/pages/ClaimDetailsDocument'));
const ClaimSubmitted = lazy(() => import('../containers/pages/ClaimSubmitted'));
const ClaimSelectorInitial = lazy(() => import('../containers/pages/ClaimSelectorInitial'));
const ClaimSelector = lazy(() => import('../containers/pages/ClaimSelector'));
const FNOLSections = lazy(() => import('../containers/pages/FNOLSections'));
const PreDocumentUploadPage = lazy(() => import('../containers/pages/FNOLDocumentsUploader'));
const EmailPage = lazy(() => import('../containers/pages/Email'));

export const authRoutes = [
  {
    key: 'claimsLanding',
    path: '/claims',
    exact: true,
    component: ClaimsLanding,
  },
  {
    key: 'claimSubmitted',
    path: '/claims/fnol/submitted',
    exact: true,
    component: ClaimSubmitted,
  },
  {
    key: 'claimSelectorInitial',
    path: '/claims/fnol/claim-selector',
    exact: true,
    component: ClaimSelectorInitial,
  },
  {
    key: 'claimSelector',
    path: '/claims/fnol/claim-selector/:decisionPointId',
    exact: true,
    component: ClaimSelector,
  },
  {
    key: 'fnolSections',
    path: '/claims/fnol/sections/:sectionNames(.*)?',
    component: FNOLSections,
  },
  {
    key: 'PreDocumentUploadPage',
    path: '/claims/fnol/documents/:documentName',
    component: PreDocumentUploadPage,
  },
  {
    key: 'claimDetails',
    path: '/claims/:claimReference/details',
    exact: true,
    component: ClaimDetails,
  },
  {
    key: 'claimDetailsDocument',
    path: '/claims/:claimReference/documents/:documentName',
    exact: true,
    component: ClaimDetailsDocument,
  },
  {
    key: 'email',
    path: '/claims/emails/:id',
    exact: true,
    component: EmailPage,
  },
];
