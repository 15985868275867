import React from 'react';
import { kebabCase } from 'lodash/fp';
import ReactHtmlParser from 'react-html-parser';
import { useIntl } from 'react-intl';
import { Col } from 'reactstrap';
import { Alignment, HeadingTag, IImageBlock, BlockComponentSpacing } from '@whitelabel/helpers/types';
import Alert from '../../Alert';
import ScrollToAnchor from '../ScrollToAnchor';
import { transformLink } from '../../../helpers/transform';
import { StyledHeading, StyledTitle, StyledImage, StyledLeadText } from './styledHeadingBlock';

export interface IHeadingBlockProps {
  title?: string;
  headingTag?: HeadingTag;
  leadText?: string | React.ReactNode;
  image?: IImageBlock;
  alignment?: Alignment;
  anchorId?: string;
  namePrefix?: string;
  className?: string;
  children?: React.ReactNode;
  shouldParse?: boolean;
  spacing?: BlockComponentSpacing;
  errorMessage?: string;
  successMessage?: string;
  shouldTitleBeUppercase?: boolean;
  headingTextColor?: string;
  titleDataTestId?: string;
}

const HeadingBlock = ({
  title,
  headingTag = 'h1',
  leadText,
  image,
  alignment = 'start',
  anchorId,
  namePrefix,
  className,
  children,
  shouldParse = false,
  spacing = 'md',
  errorMessage,
  successMessage,
  shouldTitleBeUppercase = true,
  headingTextColor,
  titleDataTestId,
}: IHeadingBlockProps): JSX.Element => {
  const intl = useIntl();
  const kebabCaseTitle = title ? kebabCase(title) : '';
  return (
    <StyledHeading
      name={namePrefix ? `${namePrefix}-${kebabCaseTitle}` : kebabCaseTitle}
      alignment={alignment}
      className={`${className} heading-block`}
      data-test-id="Heading"
      spacing={spacing}
      id={anchorId}
    >
      <ScrollToAnchor anchor={anchorId} />
      {image && (
        <StyledImage
          $rtl={intl.bidi}
          image={image}
          altText={image.title || title}
          alignment={alignment}
          spacing={spacing}
        />
      )}
      {title && (
        <StyledTitle
          as={headingTag}
          className="heading-block__title"
          data-test-id={titleDataTestId || 'Heading-title'}
          $shouldTitleBeUppercase={shouldTitleBeUppercase}
          headingTextColor={headingTextColor}
        >
          <span>
            {shouldParse
              ? ReactHtmlParser(title, {
                  transform: transformLink(intl),
                })
              : title}
          </span>
        </StyledTitle>
      )}

      {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
      {successMessage && <Alert color="success">{successMessage}</Alert>}

      {leadText && leadText !== '<p></p>' && (
        <StyledLeadText data-test-id="Heading-leadText">
          <Col md={alignment === 'center' ? { size: 10, offset: 1 } : undefined}>
            {shouldParse && typeof leadText === 'string'
              ? ReactHtmlParser(leadText, {
                  transform: transformLink(intl),
                })
              : leadText}
          </Col>
        </StyledLeadText>
      )}

      {children}
    </StyledHeading>
  );
};

export default HeadingBlock;
