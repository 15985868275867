import React from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as IconChevronUp } from '@whitelabel/media/icons/small/chevron-up.svg';
import { ReactComponent as IconChevronDown } from '@whitelabel/media/icons/small/chevron-down.svg';
import messages from './messages';
import { StyledButton, StyledCollapseText } from './styledToggleButton';

interface IToggleButtonProps {
  isCollapsed: boolean;
  toggleCollapse: () => void;
}

const ToggleButton = (props: IToggleButtonProps) => {
  const { isCollapsed, toggleCollapse } = props;

  const { formatMessage } = useIntl();

  return (
    <StyledButton buttonType="tertiary" onClick={toggleCollapse}>
      {isCollapsed ? <IconChevronDown /> : <IconChevronUp />}
      <StyledCollapseText>
        {formatMessage(isCollapsed ? messages.readMoreText : messages.readLessText)}
      </StyledCollapseText>
    </StyledButton>
  );
};

export default ToggleButton;
