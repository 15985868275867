import React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import parseHtml from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';
import { Container, Row } from 'reactstrap';
import StyledDivider from '@whitelabel/component-library/src/styled/StyledDivider';
import Brand from '../Brand';
import rentalcoverLogo from '../../media/images/rentalcover-logo-dark.svg';
import { SITE_NAME } from '../../helpers/constants';
import CollapsibleText from './CollapsibleText';
import messages from './messages';
import {
  StyledFooter,
  StyledLinksWrapper,
  StyledRichText,
  StyledBrandWrapper,
  StyledLinkContainer,
  StyledCollapsibleTextWrapper,
  StyledNoDisclaimerFooter,
  StyledVerticalDivider,
  StyledMinimumCollapsibleTextWrapper,
} from './styledFooter';

const splitDisclaimer = (disclaimer: string): [string, string] => {
  const [firstDisclaimerData, ...restMainDisclaimerData] = disclaimer.split('\n\n');

  return [firstDisclaimerData, restMainDisclaimerData.join('\n\n')];
};

interface ILink {
  url: string;
  text: string;
  rel?: string;
  target?: React.HTMLAttributeAnchorTarget;
}

interface IFooterSection {
  header: React.ReactNode;
  items: ILink[];
}

const termsLink = (intl: IntlShape): ILink => ({
  url: `/${intl.locale}/terms`,
  text: 'Terms',
  rel: 'noopener noreferrer',
  target: '_blank',
});

const privacyLink = (intl: IntlShape): ILink => ({
  url: `/${intl.locale}/privacy`,
  text: 'Privacy policy',
  rel: 'noopener noreferrer',
  target: '_blank',
});

const footerList = (intl: IntlShape): IFooterSection[] => [
  {
    header: intl.formatMessage(messages.heading1),
    items: [
      {
        url: `/${intl.locale}/about-us`,
        text: intl.formatMessage(messages.footerConnect1),
      },
      {
        url: `/${intl.locale}/help/about-your-cover/how-to-contact-us`,
        text: intl.formatMessage(messages.footerConnect2),
      },
    ],
  },
  {
    header: intl.formatMessage(messages.heading2),
    items: [
      {
        url: `/${intl.locale}/blog`,
        text: intl.formatMessage(messages.footerLearn1),
      },
      {
        url: `/${intl.locale}/car-rental-insurance`,
        text: intl.formatMessage(messages.footerLearn2),
      },
      {
        url: `/${intl.locale}/help/about-your-cover/why-use-rentalcover`,
        text: intl.formatMessage(messages.footerLearn3),
      },
      {
        url: `/${intl.locale}/excess-cover`,
        text: intl.formatMessage(messages.footerLearn4),
      },
    ],
  },
  {
    header: intl.formatMessage(messages.heading3),
    items: [
      {
        url: `/${intl.locale}/accessibility`,
        text: intl.formatMessage(messages.footerLegal1),
      },
      {
        url: `/${intl.locale}/privacy`,
        text: intl.formatMessage(messages.footerLegal2),
      },
      {
        url: `/${intl.locale}/terms`,
        text: intl.formatMessage(messages.footerLegal3),
      },
      {
        url: `/${intl.locale}/modern-slavery-statement`,
        text: intl.formatMessage(messages.footerLegal4),
      },
    ],
  },
  {
    header: intl.formatMessage(messages.heading4),
    items: [
      {
        url: `/${intl.locale}/help`,
        text: intl.formatMessage(messages.footerQuick1),
      },
      {
        url: `/${intl.locale}/claim`,
        text: intl.formatMessage(messages.footerQuick2),
      },
      {
        url: `/${intl.locale}/account`,
        text: intl.formatMessage(messages.footerQuick3),
      },
    ],
  },
];

interface IFooter {
  showNavLinks?: boolean;
  showDisclaimer: 'none' | 'extended' | 'collapsed';
}

const Footer = ({ showNavLinks, showDisclaimer }: IFooter): JSX.Element => {
  const intl = useIntl();

  const brand = {
    title: SITE_NAME,
    logo: rentalcoverLogo,
  };

  const [firstDisclaimerData, restMainDisclaimerData] = splitDisclaimer(intl.formatMessage(messages.disclaimer));

  const restDisclaimerData = [
    restMainDisclaimerData,
    intl.formatMessage(messages.costComparisonDisclaimer),
    intl.formatMessage(messages.vehicleTypesDisclaimer),
  ].join('\n\n');

  const renderRentalCoverCopyright = () => (
    <span className="footer-copyright">{`${String.fromCharCode(169)} ${SITE_NAME} ${new Date().getFullYear()}`}</span>
  );

  const renderLink = (item: ILink) => (
    <a href={item.url} rel={item.rel} target={item.target}>
      {item.text}
    </a>
  );

  if (showDisclaimer === 'none') {
    return (
      <StyledFooter id="footer">
        <StyledMinimumCollapsibleTextWrapper>
          <StyledNoDisclaimerFooter>
            {renderRentalCoverCopyright()}
            <div className="footer-links">
              {renderLink(termsLink(intl))}
              <StyledVerticalDivider />
              {renderLink(privacyLink(intl))}
            </div>
          </StyledNoDisclaimerFooter>
        </StyledMinimumCollapsibleTextWrapper>
      </StyledFooter>
    );
  }

  return (
    <StyledFooter id="footer">
      <StyledLinksWrapper>
        <Container>
          <Row>
            <StyledBrandWrapper>
              <Brand {...brand} />
              {renderRentalCoverCopyright()}
            </StyledBrandWrapper>
          </Row>
        </Container>
        {showNavLinks && (
          <Container>
            <StyledDivider $gap="1.5rem" />
            <Row>
              {footerList(intl).map((column, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <StyledLinkContainer xs="12" md="3" key={index.toString()}>
                  <Row>
                    <b className="footer-column-header">{column.header}</b>
                  </Row>
                  {column.items.map((item) => (
                    <Row key={item.text}>{renderLink(item)}</Row>
                  ))}
                </StyledLinkContainer>
              ))}
            </Row>
          </Container>
        )}
      </StyledLinksWrapper>
      <StyledCollapsibleTextWrapper>
        <Container>
          <Row>
            {showDisclaimer === 'collapsed' && (
              <CollapsibleText
                firstElement={<StyledRichText>{parseHtml(DOMPurify.sanitize(firstDisclaimerData))}</StyledRichText>}
                restElement={<StyledRichText>{parseHtml(DOMPurify.sanitize(restDisclaimerData))}</StyledRichText>}
              />
            )}
            {showDisclaimer === 'extended' && (
              <StyledRichText>
                {parseHtml(DOMPurify.sanitize(firstDisclaimerData))}
                {parseHtml(DOMPurify.sanitize(restDisclaimerData))}
              </StyledRichText>
            )}
          </Row>
        </Container>
      </StyledCollapsibleTextWrapper>
    </StyledFooter>
  );
};

export default Footer;
