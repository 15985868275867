import styled from 'styled-components';
import { themeColor } from '@whitelabel/helpers/style/colors';
import { fontWeight } from '@whitelabel/helpers/style/type';

export const StyledCollapse = styled.div<{ $isOpen: boolean }>`
  width: max(100vw, 100%);
  position: fixed;
  top: 4rem;
  z-index: 1000;
  background: ${themeColor('tertiaryBg')};
  height: calc(100vh - 2rem);
  padding: 1rem;

  transition: left 1s;
  left: ${({ $isOpen }) => ($isOpen ? '0' : 'max(100vw, 100%)')};

  @media print {
    display: none;
  }
`;

export const StyledScrollableContainer = styled.div`
  overflow-y: scroll;
  position: absolute;
  height: calc(100vh - 12rem);
  width: calc(100% - 2rem);

  h1 {
    color: ${themeColor('heading')};
  }
  .nav-settings-submenu {
    font-weight: ${fontWeight('black')};
  }
`;

export const StyledNavCustomer = styled.div`
  .customer-data {
    margin-bottom: 1rem;
  }
`;
